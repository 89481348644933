import React, { useState, useLayoutEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styles from './register-user.module.scss'
import Button from '../Button'
import { errorsTDEE } from './validation'

const TDEE = ({ step, setStep, user, setUser, resultsMetric, setResultsMetric }) => {
  const [errors, setErrors] = useState({})
  // const [, setErrors] = useState({})
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (!errors.activityLevel) {
      let tdee = resultsMetric.REE
      console.log(tdee)
      switch (user.activityLevel) {
        case 'sedentary':
          tdee = tdee * 1.2
          break;
        case 'lightly-active':
          tdee = tdee * 1.375
          break;
        case 'active':
          tdee = tdee * 1.725
          break;
        case 'highly-active':
          tdee = tdee * 1.9
          break;

        default:
            break;
      }
      setResultsMetric({
        type: 'UPDATE_RESULTS',
        payload: { 'TDEE': tdee },
      })
    }
  }, [errors])


  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const errors = errorsTDEE(user)
    setErrors(errors)
  }, [user])


  const handleUser = ({ target }) => {
    setUser({
      type: 'UPDATE_PERSONAL_INFO',
      payload: { [target.name]: target.value },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = errorsTDEE(user)
    setErrors(errors)
    if (Object.keys(errors).length > 0) return

    //handle http request

    setStep(step + 1)
  }

  return (
    <form onSubmit={handleSubmit} className={styles.RegisterForm}>
      <div>
        Estimons maintenant vos besoins énergétiques quotidiens totaux (TDEE) :
      </div>
      <br/>
 
      <div>
        Quel est votre niveau d'activité ?
      </div>
      <br/>

      <div>
        <input type="radio" name="activityLevel" value="sedentary" onChange={(e) => handleUser(e)} /><b> Sédentaire</b>, peu ou pas d'exercice, travail de bureau
      </div>
      <div>
        <input type="radio" name="activityLevel" value="lightly-active" onChange={(e) => handleUser(e)} /><b> Peu actif</b>, promène le chien ou s'exerce 1 à 3 fois par semaine
      </div>
      <div>
        <input type="radio" name="activityLevel" value="active" onChange={(e) => handleUser(e)} /><b> Actif</b>, toute la journée debout (serveuse...) ou s'exerce 3 à 5 fois par semaine
      </div>
      <div>
        <input type="radio" name="activityLevel" value="highly-active" onChange={(e) => handleUser(e)} /><b> Très actif</b>, métier physique (construction...)
      </div>
 
 
      <br/>
      <div>
        (L'employé de bureau moyen qui se rend au travail en voiture trouvera que la première option est la plus adaptée).
      </div>
      <br/>

      { user.activityLevel &&
        <div>
          Vos besoins quotidiens en calories sont estimés à <b>{Math.round(resultsMetric.TDEE)}</b>.
        </div>
      }

      <div className={styles.submit}>
        <Button onClick={() => setStep(step - 1)} type="button">
          Retour
        </Button>
        <Button type="submit"> Prochaine étape</Button>
      </div>
    </form>
  )
}

TDEE.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  resultsMetric: PropTypes.object,
  setResultsMetric: PropTypes.func,
}

export default TDEE;
