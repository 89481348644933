import React from 'react'
import BMR from './bmr-step'
import TDEE from './tdee-step'
import Goal from './goal-step'
import Macro from './macro-step'
import Results from './results'

// import Completed from './completed'

const FormSteps = (step, setStep, user, setUser, resultsMetric, setResultsMetric) => [
  {
    title: 'BMR',
    content: (
      <BMR
        setStep={setStep}
        step={step}
        user={user}
        setUser={setUser}
        resultsMetric={resultsMetric}
        setResultsMetric={setResultsMetric}
      />
    ),
  },
  {
    title: 'TDEE',
    content: (
      <TDEE
        setStep={setStep}
        step={step}
        user={user}
        setUser={setUser}
        resultsMetric={resultsMetric}
        setResultsMetric={setResultsMetric}
      />
    ),
  },
  {
    title: 'Goal',
    content: (
      <Goal
        setStep={setStep}
        step={step}
        user={user}
        setUser={setUser}
        resultsMetric={resultsMetric}
        setResultsMetric={setResultsMetric}
      />
    ),
  },
  // {
  //   title: 'Macro',
  //   content: (
  //     <Macro
  //       setStep={setStep}
  //       step={step}
  //       user={user}
  //       setUser={setUser}
  //     />
  //   ),
  // },
  {
    title: 'Results',
    content: (
      <Results
        step={step}
        setStep={setStep}
        resultsMetric={resultsMetric}
      />
    ),
  },
  // {
  //   title: 'Complete',
  //   content: <Completed user={user} />,
  // },
]
export default FormSteps
