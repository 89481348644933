export const DefaultUser = {
  gender: 'man',
  age: '',
  height: '',
  weight: '',
  activityLevel: '',
  goal: '',
  bulkingExperience: '',
  macroPreference: 'average',
  macroCycle: 'no',
  macroDays: '',
  // nextOfKin: {
  //   age: '',
  //   height: '',
  // },
}

export const ResultsMetric = {
  REE: '',
  TDEE: '',
  targetTDEE: '',
  protein: '',
  fat: '',
  carbs: '',
}

export const UserReducer = (user, { type, payload }) => {
  switch (type) {
    case 'UPDATE_PERSONAL_INFO':
      return {
        ...user,
        ...payload,
      }
    default:
      return user
  }
}

export const ResultsMetricReducter = (resultsMetric, { type, payload }) => {
  switch (type) {
    case 'UPDATE_RESULTS':
      return {
        ...resultsMetric,
        ...payload,
      }
    default:
      return resultsMetric
  }
}
