import React, { useState, useLayoutEffect, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styles from './register-user.module.scss'
import Input from '../Input'
import Button from '../Button'
import { errorsBMR } from './validation'

const BMR = ({ step, setStep, user, setUser, resultsMetric, setResultsMetric }) => {
  const [errors, setErrors] = useState({})
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!errors.age && !errors.height && !errors.weight) {
      // let ree = 49.940 * user.weight + 2459.053 * (user.height / 100) - 34.014 * user.age + 122.502
      let ree = 10 * user.weight + 6.25 * user.height - 5 * user.age 
      if (user.gender === "man")
        ree += 5
      else
        ree -= 161
      setResultsMetric({
        type: 'UPDATE_RESULTS',
        payload: { 'REE': ree },
      })
    }
  }, [errors])

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const errors = errorsBMR(user)
    setErrors(errors)
  }, [user])

  const handleUser = ({ target }) => {
    setUser({
      type: 'UPDATE_PERSONAL_INFO',
      payload: { [target.name]: target.value },
    })
  }

  const handleContinue = (e) => {
    e.preventDefault()
    const errors = errorsBMR(user)
    setErrors(errors)
    if (Object.keys(errors).length > 0) return
    setStep(step + 1)
  }

  return (
    <form onSubmit={handleContinue} className={styles.RegisterForm}>
      <div>
        Commençons par estimer votre taux métabolique de base (BMR) :
      </div>
      <br/>
      <div>
          <input type="radio" name="gender" value="man" defaultChecked={user.gender === 'man'} onChange={(e) => handleUser(e)} />Homme
          <input type="radio" name="gender" value="woman" defaultChecked={user.gender === 'woman'} onChange={(e) => handleUser(e)} />Femme
      </div>
      <Input
        type="number"
        name="age"
        value={user.age}
        label="Age :"
        onChange={(e) => handleUser(e)}
        error={errors.age}
      />
      <Input
        type="number"
        name="height"
        value={user.height}
        label="Taille (cm) :"
        onChange={(e) => handleUser(e)}
        error={errors.height}
      />
      <Input
        type="number"
        name="weight"
        value={user.weight}
        label="Poids (kg) :"
        onChange={(e) => handleUser(e)}
        error={errors.weight}
      />
      <br/>
      { !errors.age && !errors.height && !errors.weight && user.age && user.height && user.weight && <div>
        <b>{Math.round(resultsMetric.REE)} calories</b>, c'est ce que vous dépensez si vous dormez 24h, c'est à dire sans activité.
      </div>
}
      <Button type="submit">Prochaine étape</Button>
    </form>
  )
}

BMR.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  resultsMetric: PropTypes.object,
  setResultsMetric: PropTypes.func,
}

export default BMR;
