import React, { useReducer, useState } from 'react'
import { UserReducer, DefaultUser, ResultsMetricReducter, ResultsMetric } from './user-reducer'
import styles from './register-user.module.scss'
import FormSteps from './form-steps'

const RegisterUser = () => {
  let [step, setStep] = useState(0)
  const [user, setUser] = useReducer(UserReducer, DefaultUser)
  const [resultsMetric, setResultsMetric] = useReducer(ResultsMetricReducter, ResultsMetric)
  let steps = FormSteps(step, setStep, user, setUser, resultsMetric, setResultsMetric)

  return (
    <div className={styles.Register}>
      <br/>
      <h1>THE MACRO CALCULATOR</h1>
      <br/>
      <div className={styles.RegisterBody}>{steps[`${step}`].content}</div>
      <h4>
        {step + 1}/{steps.length}
      </h4>
    </div>
  )
}
export default RegisterUser
