import React from 'react'
import PropTypes from 'prop-types'
import styles from './register-user.module.scss'
import Button from '../Button'

const Results = ({ setStep, step, resultsMetric }) => {
  return (
  <div className={styles.Completed}>
    <h2>RESULTATS</h2>

    <div>
      Votre objectif d'apport calorique quotidien est de <b>{Math.round(resultsMetric.targetTDEE)} calories</b> avec les macros suivantes :
    </div>

    <br/>
    <div>
        - Protéines : <b>{Math.round(resultsMetric.proteins)}g</b>
        </div>
        <div>

        - Lipides : <b>{Math.round(resultsMetric.fat)}g</b>
        </div>
        <div>
        - Glucides : <b>{Math.round(resultsMetric.carbs)}g</b>
    </div>
    <br/>
    <div>
      <b>Note importante</b> : Ceci est une estimation de vos calories à manger par jour, néanmoins attention si vous ne perdez pas au bout de 2 semaines donc aucun changement sur vos cm et vos kilos, alors vous n'êtes pas en déficit calorique.
    </div>
    <br/>

    <div className={styles.submit}>
      <Button onClick={() => setStep(step - 1)} type="button">
        Retour
      </Button>
    </div>
  </div>
  )
}

Results.propTypes = {
  setStep: PropTypes.func,
  step: PropTypes.number,
  resultsMetric: PropTypes.object,
}

export default Results;
