export const errorsBMR = (values) => {
  let errors = {}
  if (!values.age || values.age <= 0) {
    errors.age = 'L\'age est requis'
  }
  if (!values.height || values.height <= 0) {
    errors.height = 'La taille (cm) est requise'
  }
  if (!values.weight || values.weight <= 0) {
    errors.weight = 'Le poids (kg) est requis'
  }
  return errors
}

export const errorsTDEE = (values) => {
  let errors = {}
  if (!values.activityLevel) {
    errors.age = 'Le niveau d\'activité est requis'
  }
  return errors
}

export const errorsGoal = (values) => {
  let errors = {}
  if (!values.goal) {
    errors.goal = 'L\'objectif est requis'
  }
  return errors
}

export const errorsMacro = (values) => {
  let errors = {}
  if (values.macroCycle === "yes" && !values.macroCycleDays) {
    errors.macroCycleDays = 'Le nombre de jour est requis'
  }
  return errors
}