import React, { useState, useLayoutEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styles from './register-user.module.scss'
import Button from '../Button'
import { errorsGoal } from './validation'

const Goal = ({ step, setStep, user, setUser, resultsMetric, setResultsMetric }) => {
  const [errors, setErrors] = useState({})
  // const [, setErrors] = useState({})
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (!errors.goal) {
      let tdee = resultsMetric.TDEE
      switch (user.goal) {
        case 'classic':
          tdee = tdee - (tdee * 20 / 100)
          break;
        // case 'aggressive':
        //   tdee = tdee - (tdee * 35 / 100)
        //   break;
        case 'booty':
          break;
        default:
          break;
      }
      setResultsMetric({
        type: 'UPDATE_RESULTS',
        payload: { 'targetTDEE': tdee },
      })
    }
  }, [errors])


  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const errors = errorsGoal(user)
    setErrors(errors)
  }, [user])


  const handleUser = ({ target }) => {
    setUser({
      type: 'UPDATE_PERSONAL_INFO',
      payload: { [target.name]: target.value },
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = errorsGoal(user)
    setErrors(errors)
    if (Object.keys(errors).length > 0) return

    const targetTDEE = resultsMetric.targetTDEE
    let proteins = 1.5 * user.weight
    const kcals = (targetTDEE - (proteins * 4)) / 2 
    let fat = kcals / 9
    let carbs = kcals / 4

    if (proteins > 110) {
      let excess = ((proteins - 110) * 4) / 2
      proteins = 110
      fat = fat + excess / 9
      carbs = carbs + excess / 4
    }

    
    setResultsMetric({
        type: 'UPDATE_RESULTS',
        payload: {
          'proteins': proteins,
          'fat': fat,
          'carbs': carbs
        },
    })

    setStep(step + 1)
  }

  return (
    <form onSubmit={handleSubmit} className={styles.RegisterForm}>
      <div>
        Ajustons maintenant cela en fonction de votre objectif :
      </div>
      <br/>
 
      <div>
        <input type="radio" name="goal" value="classic" onChange={(e) => handleUser(e)} /><b> Perte de poids classique</b>
      </div>
 
      {/* <div>
        <input type="radio" name="goal" value="aggressive" onChange={(e) => handleUser(e)} /><b> Perte de poids aggressive</b>
      </div> */}

      <div>
        <input type="radio" name="goal" value="booty" onChange={(e) => handleUser(e)} /><b> Galber mes fesses</b> - (uniquement dans le cadre du programme booty express)
      </div>

      <br/>

      { user.goal && 
        <div>
          Votre apport calorique quotidien cible est de <b>{Math.round(resultsMetric.targetTDEE)}</b>.
        </div>
      }

      <div className={styles.submit}>
        <Button onClick={() => setStep(step - 1)} type="button">
          Retour
        </Button>
        <Button type="submit"> Prochaine étape</Button>
      </div>
    </form>
  )
}

Goal.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  resultsMetric: PropTypes.object,
  setResultsMetric: PropTypes.func,
}

export default Goal;
